export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const DROP_MENU = "DROP_MENU";
export const CHAGE_PATH = "CHANGE_PATH";
export const CHAGE_PAGE = "CHANGE_PAGE";
export const AUTHENTICATION = "AUTHENTICATION";
export const RE_AUTHENTICATION = "RE_AUTHENTICATION";
export const NOTIFICATION = "NOTIFICATION";
export const UPDATE_LIST = "UPDATE_LIST";

/* Tasks */
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASKS = "UPDATE_TASKS";
export const CREATE_TASKS = "CREATE_TASKS";

export const COLOR_THEME = "COLOR_THEME";
export const CHANGE_SEARCH = "CHANGE_SEARCH";
export const CHANGE_FILTERS = "CHANGE_FILTERS";

export const LINKED_PRODUCTS = "LINKED_PRODUCTS";

export const SET_NOTIFICATION_DATA = "APP/SET_NOTIFICATION_DATA";
export const REMOVE_NOTIFICATION_DATA = "APP/REMOVE_NOTIFICATION_DATA";
export const COLLAPSE_NOTIFICATION = "APP/COLLAPSE_NOTIFICATION";