export const SET_ESTABLISHMENT_FILTERS = 'DASHBOARD/SET_ESTABLISHMENT_FILTERS';
export const SET_CURRENT_RESERVATION_DETAILS = 'DASHBOARD/SET_CURRENT_RESERVATION_DETAILS';
export const SET_RESERVATIONS_DATAS = 'DASHBOARD/SET_RESERVATIONS_DATAS';
export const CHANGE_REFRESH = 'DASHBOARD/CHANGE_REFRESH';
export const UPDATE_CURRENT_RESERVATION_DETAILS = 'DASHBOARD/UPDATE_CURRENT_RESERVATION_DETAILS';
export const SET_APPARTA_PAY_DATAS = 'DASHBOARD/SET_APPARTA_PAY_DATAS';
export const CHANGE_APPARTA_PAY_REFRESH = 'DASHBOARD/CHANGE_APPARTA_PAY_REFRESH';
export const CHANGE_USER_FILTER = 'DASHBOARD/CHANGE_USER_FILTER';
export const CHANGE_PANEL_SIZE = 'DASHBOARD/CHANGE_PANEL_SIZE';

export const CHANGE_REPORT_PER_DAYS_OF_WEEK = 'DASHBOARD/CHANGE_REPORT_PER_DAY'